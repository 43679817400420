<template>
  <div class=" pb-6 home-page">
    <NavBar :home-data="homeData"/>

    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div class="flex flex-col items-center justify-center xl:flex-row">

        <div class="w-full max-w-5xl xl:px-8 xl:w-10/12">
          <div class="bg-white rounded shadow-2xl p-7 sm:p-10">
             <h3 class="mb-4 text-2xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
               Anmelden
            </h3>
            <form class="text-left w-full"
                  @submit="submitForm">
              <div class="w-full flex">
                <div class="w-full md:w-1/2 pr-4">
                  <div class="mb-1 sm:mb-2">
                    <label for="firstName" class="inline-block mb-1 font-medium">Vorname<span class="text-danger">*</span></label>
                    <input
                      placeholder=""
                      required=""
                      type="text"
                      v-model="firstName"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="firstName"
                      name="firstName"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="lastName" class="inline-block mb-1 font-medium">Familienname, Nachname<span class="text-danger">*</span></label>
                    <input
                      placeholder="Watson"
                      required=""
                      type="text"
                      v-model="lastName"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="lastName"
                      name="lastName"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="username" class="inline-block mb-1 font-medium">Nutzername<span class="text-danger">*</span></label>
                    <input
                      placeholder="username"
                      required=""
                      type="text"
                      v-model="username"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="username"
                      name="username"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="company" class="inline-block mb-1 font-medium">Firmen Name<span class="text-danger">*</span></label>
                    <input
                      placeholder="muster firma"
                      required=""
                      type="text"
                      v-model="company"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="company"
                      name="company"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="shoptype" class="inline-block mb-1 font-medium">Geschäftstyp<span class="text-danger">*</span></label>
                    <select
                      v-model="shopType"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="shoptype"
                      required=""
                      name="shoptype"
                    >
                      <option value="Kiosk">Kiosk</option>
                      <option value="Handy Shop">Handy Shop</option>
                      <option value="Internet Cafe">Internet Cafe</option>
                      <option value="Lebensmittel">Lebensmittel</option>
                      <option value="Others">Others</option>
                    </select>

                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="address" class="inline-block mb-1 font-medium">Adresse<span class="text-danger">*</span></label>
                    <input
                      placeholder=""
                      required=""
                      type="text"
                      v-model="address"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="address"
                      name="address"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="hNo" class="inline-block mb-1 font-medium">Hausnummer <span class="text-danger">*</span></label>
                    <input
                      placeholder=""
                      required=""
                      type="text"
                      v-model="hNo"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="hNo"
                      name="hNo"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="postalCode" class="inline-block mb-1 font-medium">Postleitzahl <span class="text-danger">*</span></label>
                    <input
                      placeholder=""
                      required=""
                      type="number"
                      v-model="postalCode"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="postalCode"
                      name="postalCode"
                    />
                  </div>
                </div>
                <div class="w-full md:w-1/2 pl-4">


                  <div class="mb-1 sm:mb-2">
                    <label for="city" class="inline-block mb-1 font-medium">Stadt<span class="text-danger">*</span></label>
                    <input
                      placeholder=""
                      required=""
                      type="text"
                      v-model="city"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="city"
                      name="city"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="email" class="inline-block mb-1 font-medium">E-Mail<span class="text-danger">*</span></label>
                    <input
                      placeholder="example@gmail.com"
                      required=""
                      type="email"
                      v-model="email"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="email"
                      name="email"
                    />
                  </div>
<!--                  <div class="mb-1 sm:mb-2">-->
<!--                    <label for="country" class="inline-block mb-1 font-medium">Land<span class="text-danger">*</span></label>-->
<!--                    <input-->
<!--                      placeholder=""-->
<!--                      required=""-->
<!--                      type="text"-->
<!--                      v-model="country"-->
<!--                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"-->
<!--                      id="country"-->
<!--                      name="country"-->
<!--                    />-->
<!--                  </div>-->
                  <div class="mb-1 sm:mb-2">
                    <label for="landline" class="inline-block mb-1 font-medium">Festnetz</label>
                    <input
                      placeholder=""
                      type="text"
                      v-model="landline"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="landline"
                      name="landline"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="mobile" class="inline-block mb-1 font-medium">Mobiltelefon <span class="text-danger">*</span></label>
                    <input
                      placeholder="+491234567890"
                      required=""
                      type="text"
                      v-model="mobile"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="mobile"
                      name="mobile"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="mobile1" class="inline-block mb-1 font-medium">Alternative Handy</label>
                    <input
                      placeholder="+491234567890"
                      type="text"
                      v-model="mobile1"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="mobile1"
                      name="mobile1"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="stnr" class="inline-block mb-1 font-medium">Stnr</label>
                    <input
                      required=""
                      placeholder=""
                      type="text"
                      v-model="stnr"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="stnr"
                      name="stnr"
                    />
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="ustldnr" class="inline-block mb-1 font-medium">Ustldnr</label>
                    <input
                      required=""
                      placeholder=""
                      type="text"
                      v-model="ustldnr"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="ustldnr"
                      name="ustldnr"
                    />
                  </div>
                </div>
              </div>


              <div class="mt-4 mb-2 w-full sm:mb-4">

                <div class="mb-1 sm:mb-2">
                  <label class="inline-flex items-center">
                    <input type="checkbox"
                           v-model="termsAccepted"
                           class="
                           whitespace-nowrap
                          rounded
                          border-gray-300
                          text-indigo-600
                          shadow-sm
                          focus:border-indigo-300
                          focus:ring
                          focus:ring-offset-0
                          focus:ring-indigo-200
                          focus:ring-opacity-50
                        ">
                    <span class="ml-2">Ich stimme AGBs</span>
                  </label>
                </div>
              </div>
              <div class="mt-4 mb-2 w-full md:w-1/3 sm:mb-4 mx-auto">
                <button
                  type="submit"
                  :disabled="!validForm"
                  class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded focus:shadow-outline focus:outline-none"
                  :class="!validForm ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-900 cursor-pointer hover:bg-gray-800 shadow-md'"
                >
                  Registrieren
                </button>
              </div>
              <p v-if="errorMessage" class="text-xs text-red-500 sm:text-sm">
                {{ errorMessage }}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed z-400001 inset-0 overflow-y-auto" v-if="showRegisterSuccess">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">

                <svg id="Capa_1"
                     class="text-success"
                     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16"
                     stroke="currentColor"
                     viewBox="0 0 352.62 352.62" style="enable-background:new 0 0 352.62 352.62;" xml:space="preserve">
                <g>
                  <path
                    d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664   c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648   c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704   c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145   C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611   c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                </g>

                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Erfolg
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Wir haben Ihre Registrierung erhalten. Wir werden uns bald bei Ihnen melden.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button"
                    @click="showRegisterSuccess = false"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
              Schließen
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import axios from "@/axios";
import Vue from "vue";
import {VueReCaptcha} from "vue-recaptcha-v3";

export default {
  name: 'OrgHomeRegister',
  data() {
    return {
      homeData: null,
      showRegisterSuccess: false,
      firstName: '',
      lastName: '',
      username: '',
      company: '',
      address: '',
      hNo: '',
      postalCode: '',
      shopType: '',
      email: '',
      city: '',
      // country: '',
      landline: '',
      mobile: '',
      mobile1: '',
      stnr: '',
      ustldnr: '',

      message: '',
      errorMessage: '',
      termsAccepted: false,
    }
  },
  components: {NavBar,},
  computed: {
    validForm() {
      return this.firstName !== '' && this.lastName !== '' && this.username !== '' && this.company !== '' &&
        this.address !== '' && this.hNo !== '' &&
        this.postalCode !== '' && this.email !== '' &&
        this.city !== '' &&
        this.shopType !== '' && this.mobile !== '' &&
        this.validEmail(this.email) && this.validPhone(this.mobile) && !isNaN(this.postalCode) && this.termsAccepted;
    }
  },
  methods: {
    reset() {
      this.firstName = ''
      this.lastName = ''
      this.username = ''
      this.company = ''
      this.address = ''
      this.hNo = ''
      this.postalCode = ''
      this.email = ''
      this.city = ''
      // this.country = ''
      this.shopType = ''
      this.landline = ''
      this.mobile = ''
      this.mobile1 = ''
      this.stnr = ''
      this.ustldnr = ''
    },
    toggleContact() {
      this.showRegisterSuccess = !this.showRegisterSuccess;
    },
    checkOrganisation() {
      this.$store.dispatch('auth/fetchOrgDetails')
        .then((data) => {
          this.balancesError = false
          this.fetchingBalances = false
          if (data.message_type == 1) {
            this.loadData()
          } else {
            // this.$router.push({ path: '/pages/error-404' })
          }
        })
        .catch((error) => {
          this.$router.push({path: '/pages/error-404'})
        })
    },
    loadData() {
      const data = localStorage.getItem('home')
      this.homeData = JSON.parse(data)
      this.pages = this.homeData.pages
      // if(!this.homeData.registration_form) {
      //   this.$router.push({path: '/'})
      // }
      try {
        this.currentPage = this.homeData.pages[0]
      } catch (e) {
      }
    },
    validEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPhone(phone) {
      // const re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
      const re = /^\+(?:[0-9]){6,14}[0-9]$/gm;
      return re.test(phone);
    },
    loadRecaptcha() {
      try {
        Vue.use(VueReCaptcha, { siteKey: JSON.parse(localStorage.getItem('home')).recaptcha })
      } catch (e) {
        // console.error(e)
        setTimeout(this.loadRecaptcha, 1500)
      }
    },
    async submitForm(e) {
      this.errorMessage = ''
      e.preventDefault()
      if (this.mobile1 && !this.validPhone(this.mobile1)) {
        this.errorMessage = 'Invalid Alternative mobile';
        return;
      }

      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      this.$recaptchaInstance.showBadge()
      // Loading
      this.$vs.loading()


      const recaptcha = this.$recaptchaInstance
      // Hide reCAPTCHA badge:
      recaptcha.hideBadge()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')


      let payload = {
        first_name: this.firstName,
        last_name: this.lastName,
        username: this.username,
        company: this.company,
        address: this.address,
        house_no: this.hNo,
        postal: this.postalCode,
        email: this.email,
        city: this.city,
        shop_type: this.shopType,
        land_line: this.landline,
        mobile: this.mobile,
        mobile_1: this.mobile1,
        stnr: this.stnr,
        ustldnr: this.ustldnr,
        token: token,
      }
      let formData = new FormData()
      Object.keys(payload).forEach(key => {
        formData.append(key, payload[key])
      })
      this.$vs.loading()
      axios.post('/add_registration/', formData).then((data) => {
        this.showRegisterSuccess = true;
        this.$vs.loading.close()
        this.reset();
      }).catch(err => {
        console.log(err);
        this.errorMessage = 'Failed to submit request'
      })
    },
  },
  mounted() {
    this.loadRecaptcha();
    const data = localStorage.getItem('home')

    if (data) {
      this.loadData()
    } else {
      this.checkOrganisation()
    }
  },

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Sample `apply` at-rules with Tailwind CSS
.container {
@apply min-h-screen flex justify-center items-center text-center mx-auto;
}
*/
.container {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  font-family: 'Quicksand',
  'Source Sans Pro',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  sans-serif;
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}

.subtitle {
  font-weight: 300;
  font-size: 42px;
  color: #526488;
  word-spacing: 5px;
  padding-bottom: 15px;
}

.links {
  padding-top: 15px;
}

.z-400001 {
  z-index: 400001 !important;
}
</style>
